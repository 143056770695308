import React from 'react';
import {Link} from 'gatsby';
import {GatsbyImage, IGatsbyImageData, getImage} from 'gatsby-plugin-image';

import classes from './news-grid-item.module.css';

interface NewsGridItemProp {
  id: string;
  title: string;
  image: IGatsbyImageData;
}

const NewsGridItem: React.FC<NewsGridItemProp> = ({id, title, image}) => {
  return (
    <Link to={`/news/${id}`} className={classes.news_container}>
      <GatsbyImage image={getImage(image)!} alt={title} className={classes.news_image} />
      <div className={classes.news_item}>
        <h2 className={classes.news_title}>{title}</h2>
        <span className={classes.news_details}>детальніше</span>
      </div>
    </Link>
  );
};

export default NewsGridItem;
