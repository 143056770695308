import React, {useContext} from 'react';
import {graphql} from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import {GatsbyImage, IGatsbyImageData, getImage} from 'gatsby-plugin-image';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import NewsGridItem from '../components/news-grid-item';
import NewsSlider from '../components/news-slider';
import NewsListItem from '../components/news-list-item';
import classes from './open-news-template.module.css';

type NewsQueryResult = {
  allNewsYaml: {
    edges: {
      node: {
        id: string;
        title: string;
        image: IGatsbyImageData;
        date: string;
      };
    }[];
  };
};

export const query = graphql`
  query {
    allNewsYaml(limit: 3) {
      edges {
        node {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300, quality: 100, placeholder: BLURRED)
            }
          }
          date
        }
      }
    }
  }
`;

interface OpenNewsProps {
  data: NewsQueryResult;
  pageContext: {
    id: string;
    image: IGatsbyImageData;
    title: string;
    text: string;
    date: string;
  };
}

const OpenNews: React.FC<OpenNewsProps> = ({data, pageContext}) => {
  const news = data.allNewsYaml.edges;
  const {image, title, text, date} = pageContext;
  const {theme, isDesktop} = useContext(GlobalStateContext);

  return (
    <Layout>
      <Container>
        <article
          className={clsx(classes.news, theme == 'regular' ? classes.news_dots : classes.news_black_and_white)}
          lang="uk"
        >
          {theme == 'regular' && <GatsbyImage className={classes.news_image} image={getImage(image)!} alt={title} />}
          <h2 className={classes.news_title}>{title}</h2>
          <div className={classes.news_text}>{ReactHtmlParser(text)}</div>
          <span className={clsx(classes.news_date, theme !== 'regular' && classes.news_date_black_and_white)}>
            Дата публікації: {date}
          </span>
        </article>
        <PageTitle title="Останні новини" />
        <SEO title={title} />
      </Container>
      {theme == 'regular' ? (
        isDesktop ? (
          <div className={classes.latest_news_grid}>
            {news.map(({node}) => (
              <NewsGridItem key={node.id} id={node.id} title={node.title} image={node.image} />
            ))}
          </div>
        ) : (
          <NewsSlider />
        )
      ) : (
        <Container className={classes.latest_news_list}>
          {news.map(({node}) => (
            <NewsListItem key={node.id} id={node.id} title={node.title} date={node.date} />
          ))}
        </Container>
      )}
    </Layout>
  );
};

export default OpenNews;
